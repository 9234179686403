/* You can add global styles to this file, and also import other style files */

body, html {
  height: 100%;
  width: 100%;
  margin: 0;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-content {
  width: auto !important;
  min-width: 22px !important;
  padding: 0 3px !important;
}

.Window__Component-sc-1wwhwms-0 {
  z-index: 1 !important;
  bottom: 0px !important;
  left: 20px !important;
  width: 80px !important;
}

.old-price {
  text-decoration: line-through;
  text-decoration-color: red;
  text-decoration-thickness: 2px;
  margin-left: 10px;
  margin-right: 10px;
  color: #5E5E5E
}
