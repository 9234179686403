@use '~@angular/material' as mat;

.dark-mode {
  @import "~@angular/material/theming";

  @include mat.core();

  $angular-primary: mat.define-palette(mat.$indigo-palette, 500, 100, 900);
  $angular-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
  $angular-warn: mat.define-palette(mat.$red-palette);

  $angular-default-theme: mat.define-light-theme(
                  (
                          color: (
                                  primary: $angular-primary,
                                  accent: $angular-accent,
                                  warn: $angular-warn,
                          ),
                  )
  );


  $angular-dark-theme: mat.define-dark-theme(
                  (
                          color: (
                                  primary: $angular-primary,
                                  accent: $angular-accent,
                                  warn: $angular-warn,
                          ),
                  )
  );

  @include mat.all-component-themes($angular-default-theme);
  @include mat.all-component-colors($angular-dark-theme);

  *, *::placeholder {
    color: #fff !important;
  }

  .loading {
    background-color: #424242 !important;
  }

  .mat-progress-spinner circle, .mat-spinner circle {
    stroke: #fff;
  }

  .default-total, .discounted-total {
    background-color: transparent !important;
  }

  snack-bar-container,
  mat-bottom-sheet-container,
  mat-nav-list,
  mat-selection-list,
  mat-stepper,
  mat-option,
  .bg-light,
  .dropdown-menu,
  .mat-menu-content,
  .mat-drawer-container,
  .mat-select-panel .mat-input-element,
  .mat-select-panel-wrap,
  .checkout-container,
  .payment-container,
  .page-link,
  mat-card.most-popular-tier,
  mat-card.our-tiers,
  app-countdown .flex-item,
  app-countdown .counter {
    background-color: #424242 !important;
  }

  .page-item.active .page-link {
    border: 1px dashed #fff;
    font-weight: bold;
  }

  .adyen-checkout__dropdown *, .adyen-checkout__input::placeholder, .adyen-checkout__input:placeholder-shown {
    color: #333 !important;
  }

  .checkout-container mat-stepper button,
  .checkout-container .right {
    background-color: #888888 !important;
  }

  .dropdown-menu {
    border: 1px solid rgb(255 255 255 / 15%) !important;
  }

  .mat-card {
    box-shadow: 5px 5px 11px 2px rgb(255 255 255 / 20%), 0px 1px 1px 0px rgb(255 255 255 / 14%), 0px 1px 3px 0px rgb(255 255 255 / 12%) !important;
  }
}
